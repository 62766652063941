import { faCalendarDay, faCube, faLayerGroup, faNewspaper } from "@fortawesome/free-solid-svg-icons";
import ListGroupU from "../../../components/ui/ListGroupU";
import Keys from "../../../utils/constants";

const Common = () => {
    const listGroup = [
        { name: Keys.CATEGORIES, icon: faLayerGroup, link: '/informations/categories' },
        { name: Keys.PRODUCTS, icon: faCube, link: '/informations/products' },
        { name: Keys.EVENTS, icon: faCalendarDay, link: '/informations/events' },
        { name: Keys.NEWS, icon: faNewspaper, link: '/informations/news' }
    ];

    return (
        <div className="card-16-outline m-3 p-3">
            <span className="fs-3 medium mb-2">{Keys.INFORMATIONS}/{Keys.COMMON}</span>
            <ListGroupU items={listGroup}/>
        </div>
    );
}

export default Common;