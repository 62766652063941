import { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faTrash, faPlus, faSearch, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import InputField from '../../components/form/InputField';
import TableStd from '../../components/ui/TableStd';
import ModalU from '../../components/ui/ModalU';
import DropdwonU from '../../components/ui/DropdownU'
import AxiosHelper from '../../api/AxiosHelper';
import Keys from '../../utils/constants';
import Status from '../../components/ui/Status';
import Selectable from '../../components/ui/Selectable';
import PaginationU from '../../components/ui/PaginationU';
import LimitU from '../../components/ui/LimitU';
import DataNotFound from '../../components/ui/DataNotFound';

const Main = () => {
    const navigate = useNavigate();
    const [modalUserDeletionShow, setModalUserDeletionShow] = useState(false);
    const [userToBeDeleted, setUserToBeDeleted] = useState({});
    
    const [users, setUsers] = useState([]);
    const [usersCount, setUsersCount] = useState(0);
    const [statuses, setStatuses] = useState([]);
    const [statusId, setStatusId] = useState('');
    const [roles, setRoles] = useState([]);
    const [roleId, setRoleId] = useState('');
    const [findUser, setFindUser] = useState("");

    const [filter, setFilter] = useState({page:1, limit:10});

    
    useEffect(() => {
        getUsers();
    },[filter]);

    const getUsers = () => {
        AxiosHelper.getUsers(filter).then((res) => {
            if (res.status == 200) {
                setUsers(res.data.data);
                setUsersCount(res.data.count);
            }
        }).catch((e) => { console.log(e); });
    }

    // activate function in future
    const getRoles = () => {
        AxiosHelper.getRoles().then((res) => {
            if (res.status == 200) {
                let data = res.data?.data;
                data.unshift(Keys.ALL);
                setRoles(data);
            }
        }).catch((e) => { console.log(e); });
    }

    const deleteUser = () => {
        AxiosHelper.deleteUser(userToBeDeleted.id).then((res) => {
            if (res.status == 200) {
                // todo
            }
        }).catch((e) => { console.log("TEMPORARY NOT ACCESSIBLE REQUEST :|"); });
    }

    const modelAttrs = [ 
        { 
            name: Keys.FULLNAME,
            value: (attr) => { return(<Link to={`/users/${attr.id}`} className='no-decor'>{attr.fullName}</Link>) } 
        },
        {
            name: Keys.PHONE_NUMBER,
            value: (attr) => { return(<>{attr.phoneNumber}</>) }
        },
        {
            name: Keys.REGISTRATION_DATE,
            value: (attr) => { return(<>{attr.registrationDate}</>) }
        },
        {
            name: Keys.STATUS,
            value: (attr) => { return(<Status status={attr.active}/>) }
        },
        {
            name: "",
            value: (attr) => {
                const options = [
                    {
                        name: Keys.SHOW,
                        icon: faEye,
                        color: "text-dark",
                        onClick: () => { navigate(`/users/${attr.id}`) },
                    },
                    {
                        name:Keys.DELETE,
                        icon: faTrash,
                        color: "text-danger",
                        onClick: () => { 
                            setModalUserDeletionShow(true);
                            setUserToBeDeleted(attr);
                        }
                    }
                ]
                return (
                    <DropdwonU className='d-flex align-items-center justify-content-center' toggleClass={"card-8-outline py-2 px-1"} icon={faEllipsisV} items={options}/>
                )
            }
        }
    ];

    return (
        <div className="d-flex flex-column m-3 p-3 card-16-outline">
            <ModalU show={modalUserDeletionShow} title={Keys.WARNING} actionbtnvariant={"danger"} actionbtntext={Keys.DELETE} onClick={deleteUser} onHide={() => setModalUserDeletionShow(false)}>
                <div className='d-flex flex-column align-items-center'>
                    <span className='text-dark medium fs-4'>{userToBeDeleted.fullName}</span>
                    <div className='mb-2'>
                        <span className='small medium me-2'>{Keys.STATUS}:</span>
                        <Status status={userToBeDeleted.active}/>
                    </div>
                    <span className='medium text-danger'>{Keys.USER_DELETION_CONFIRMATION}</span>
                </div>
            </ModalU>
            <div className='d-flex align-items-center justify-content-between'>
                <span className='fs-2 bold mb-3'>{Keys.USERS}</span>
                <button className='btn card-8 btn-outline-success bg-gradient btn-sm'>
                    <FontAwesomeIcon className='me-2' icon={faPlus}/>{Keys.CREATE_USER}
                </button>
            </div>
            { usersCount ?
                <div className='px-3 pt-3 bg-white rounded-3'>
                    <div className='d-flex align-items-center justify-content-between mb-3'>
                        <div>
                            <InputField type={"text"} name={"find_user"} value={findUser} onChange={(e) => setFindUser(e.target.value)} hint={Keys.FIND} icon={faSearch} formClass="input-field-container" inputClass="input-field bg-light"/> 
                        </div>
                        <div className='d-flex align-items-center justify-content-center'>
                            <Selectable value={statusId} setValue={setStatusId} title={Keys.STATUS} items={Keys.ENUM_STATUS} size={"sm"}/>
                            <Selectable value={roleId} setValue={setRoleId} title={Keys.ROLE} items={roles} size={"sm"} className={"ms-3"} />
                        </div>
                    </div>
                    <TableStd modelAttrs={modelAttrs} data={users}/>
                    <div className='col-12 d-flex align-items-center justify-content-between'>
                        <LimitU size={"sm"} filter={filter} setFilter={setFilter}/>
                        <PaginationU filter={filter} setFilter={setFilter} count={usersCount} />
                    </div>
                </div>
                : <DataNotFound/>
            }
            
        </div>
    );
}

export default Main;