import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import Keys from '../../utils/constants';
import ModalU from '../../components/ui/ModalU';

const LotItem = ({...props}) => {
    const [modalShow, setModalShow] = useState(false);
    
    return(
        <div className='card-8 d-flex flex-row align-items-center justify-content-between mt-3 px-3 py-2'>
            <div className='d-flex flex-column'>
                <span className='small me-2'>{Keys.LOT}: {props.index + 1}</span>
                <span className='medium me-3'>{props.lot.name}</span>
            </div>
            <button className='btn btn-sm' onClick={() => { setModalShow(true) }}>
                <FontAwesomeIcon className='text-danger fs-5' icon={faMinusCircle}/>
            </button>
            <ModalU show={modalShow} title={Keys.WARNING} actionbtnvariant={"danger"} actionbtntext={Keys.DELETE} onClick={props.onDelete} onHide={() => setModalShow(false)}>
                <div className='d-flex flex-column align-items-center justify-content-center'>
                    <span className='medium text-danger'>{Keys.DATA_DELETION_CONFIRMATION}</span>
                    <span><b>{Keys.LOT}:</b> {props.lot.name}</span>
                </div>
            </ModalU>
        </div>
    );
}

export default LotItem;