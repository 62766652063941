import axios from 'axios';
import Keys from "../utils/constants";

const DEV_URL = 'http://localhost:4000';
const PRO_URL = 'https://tender.alemtilsimat.com/api';
const baseURL = Keys.IS_DEV ? DEV_URL : PRO_URL;

const instance = axios.create({
    baseURL,
    headers: { Accept: 'application/json'},
    withCredentials: true,
});

class AxiosHelper {
    static async login(data, token) {
        return await instance.post('/auth/login', data, {
            headers: { token },
        });
    }

    static async sendOTP(data, token) {
        return await instance.put('/auth/send-otp', data, {
            headers: { token },
        });
    }

    static async confirmPhone(data, token) {
        return await instance.post('/auth/confirm-phone', data, {
            headers: { token },
        });
    }

    static async logout() {
        return await instance.post('/auth/logout');
    }

    static async signUp(data) {
        return await instance.post('/auth/signup', data);
    }

    static async getUsers(filter) {
        return await instance.get('/users', {
            params:{
                filter: filter,
                sort: filter.sort,
                limit: filter.limit,
                page: filter.page
            },
        });
    }

    static async getUser(id) {
        return await instance.get('/users/' + id);
    }

    static async deleteUser(id) {
        return await instance.delete('/users/' + id);
    }

    static async getTenders(filter) {
        return await instance.get('/tenders', {
            params:{
                filter: filter,
                sort: filter.sort,
                limit: filter.limit,
                page: filter.page
            },
        });
    }

    static async createTender(data) {
        return await instance.post('/tenders/create', {

        });
    }

    static async deleteTender(id) {
        return await instance.delete('/tenders/' + id);
    }
}

export default AxiosHelper;