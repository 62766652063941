const ChatItem = ({...props}) => {
    return (
        <div className='d-flex flex-row mt-3'>
            <span className='avatar-sm bold bg-yellow-100 text-dark'>{props.avatar}</span>
            <div className='chat ms-3 bg-purple-50'>
                <span className=''>{props.msg}</span>
                <span className='small-x'>{props.date}</span>
            </div>
        </div>
    );
}

export default ChatItem;