import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import { faFlagCheckered } from '@fortawesome/free-solid-svg-icons';
import Keys from "../../utils/constants";
import ModalU from '../../components/ui/ModalU';
import Login from './Login';
import SignUp from './SignUp';

const Wrapper = () => {
    const [modalHelpShow, setModalHelpShow] = useState(false);
    const [isLogin, setIsLogin] = useState(true);

    const toggleIsLogin = () => {
        setIsLogin(!isLogin);
    }

    return (
        <div>
            <div className="col-12 login-header">
                <div className='d-flex flex-column'>
                    <h6 className='text-white mb-1'>{Keys.CO_NAME}</h6>
                    <div className='d-flex flex-row align-items-center'>
                        <FontAwesomeIcon className='text-white logo-sm me-2' icon={faFlagCheckered}/>
                        <span className="fs-3 text-white bold">{Keys.APP_NAME}</span>
                    </div>
                </div>
                <div className='d-flex flex-row'>
                    <button type='button' className="btn btn-sm fs-6 btn-light bg-gradient me-2" onClick={toggleIsLogin}>
                        {!isLogin ? Keys.SIGN_IN : Keys.SIGN_UP }
                    </button>
                    <button className="btn btn-sm fs-6 btn-warning bg-gradient" onClick={() => setModalHelpShow(true)}>
                        <FontAwesomeIcon className='text-dark' icon={faCircleQuestion}/>
                    </button>
                    <ModalU show={modalHelpShow} title={Keys.HELP} onHide={() => setModalHelpShow(false)}>
                        {Keys.HELP_DESCRIPTION}
                    </ModalU>
                </div>
                
            </div>
            <div className="min-vh-100 d-flex flex-column align-items-center justify-content-center bg-light">
                <h3 className="text-center bold">{isLogin ? Keys.SIGN_IN : Keys.SIGN_UP}</h3>
                { isLogin ? <Login/> : <SignUp/> }
            </div>
        </div>
    );
}

export default Wrapper;