import { faTrash, faEllipsisV, faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { faComment } from '@fortawesome/free-regular-svg-icons'
import Utils from '../../utils';
import Keys from '../../utils/constants';
import DropdwonU from '../../components/ui/DropdownU'


const ParticipantItem = ({...props}) => {
    const options = [
        {
            name: Keys.PRIVATE_CHAT,
            icon: faComment,
            color: "text-dark",
            onClick: () => {  },
        },
        {
            name:Keys.DELETE,
            icon: faTrash,
            color: "text-danger",
            onClick: () => { 
                //setModalUserDeletionShow(true);
                //setUserToBeDeleted(attr);
            }
        }
    ]
    return(
        <div className="d-flex flex-row align-items-center justify-content-between my-2">
            <div className='d-flex flex-row'>
                <span className='avatar-sm border border-light bold bg-gray text-black'>{Utils.getAvatar(props.participant)}</span>
                <div className='d-flex flex-column ms-2'>
                    <span className='medium small text-primary'>@{props.participant.username}</span>
                    <span className='small-x fg-dark medium'>Online</span>
                </div>
            </div>
            <div className='d-flex flex-column'>
                <DropdwonU className='d-flex align-items-center justify-content-center' toggleClass={"text-secondary"} icon={faEllipsisH} items={options}/>
                <span className='small-x bg-blue-400 medium text-white rounded-2 mt-1 px-1'>2</span>
            </div>
        </div>
    );
}

export default ParticipantItem;