import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from './pages/Login';
import Home from './pages/Home';
import Profile from './pages/Profile';
import Settings from './pages/Settings';
import Chat from './pages/Chat';
import Users from './pages/Users';
import User from './pages/User';
import Information from './pages/Information';
import Tenders from './pages/Tenders';
import TenderNew from './pages/TenderNew';

function App() {
  
  return (
    <BrowserRouter>
        <Routes>
          <Route path='/'element={<Home />} />
          <Route path='/auth/login' element={<Login />} />
          <Route path='/profile' element={<Profile />} />
          <Route path='/settings' element={<Settings />} />
          <Route path='/chat' element={<Chat />} />
          <Route path='/users' element={<Users />} />
          <Route path='/users/:id' element={<User/>}/>
          <Route path='/informations' element={<Information/>}/>
          <Route path='/tenders' element={<Tenders />} />
          <Route path='/tenders/create' element={<TenderNew/>}/>
        </Routes>
      </BrowserRouter>
  );
}

export default App;