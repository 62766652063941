import { FormSelect } from "react-bootstrap";

const Selectable = ({...props}) => {
    return (
        <div className={props.className}>
            <span className="bold text-dark mb-2">{props.title}</span>
            <FormSelect size={props.size} value={props.value} onChange={(e) => {props.setValue(e.target.value)}}>
                {
                    props.items.map((item) => {
                        return ( <option value={item.id} key={item.id}>{item.name}</option>)
                    })
                }
            </FormSelect>
        </div>
    );
}

export default Selectable;