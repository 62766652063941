import { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import Keys from "../../utils/constants";
import ParticipantItem from "./ParticipantItem";
import InputField from '../../components/form/InputField';


const Participants = ({...props}) => {
    const [find, setFind] = useState("");

    return(
        <div className="participants">
            <span className="fs-5 medium mb-3">{Keys.PARTICIPANTS}</span>
            <InputField type={"text"} name={"find"} value={find} onChange={(e) => setFind(e.target.value)} hint={Keys.FIND} icon={faSearch} formClass="input-field-container" inputClass="input-field"/>
            <div className="mt-3"></div>
            {
                props.participants.map((participant) => {
                    return (
                        <ParticipantItem participant={participant} key={participant.id}/>
                    )
                })
            }
        </div>
    );
}

export default Participants;