import { useState } from 'react';
import { Toast, ToastBody, ToastContainer, ToastHeader } from 'react-bootstrap';

const ToastU = ({...props}) => {
    return (
        <ToastContainer className='p-3' position='top-end' style={{ zIndex: 1}}>
            <Toast bg={props.variant} onClose={() => props.setShow(false)} show={props.show} delay={3000} autohide>
                <ToastHeader>
                    <span className="rounded-1 bg-dark p-2 me-2" />
                    <strong className="me-auto">{props.title}</strong>
                    <small>{props.time}</small>
                </ToastHeader>
                <ToastBody className={(props.variant === 'dark' || 'danger' || 'success' || 'secondary') && 'text-white'}>
                    {props.content}
                </ToastBody>
            </Toast>
        </ToastContainer>
    );
}

export default ToastU;