import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import Keys from "../../utils/constants";

const NewsItem = ({...props}) => {
    return (
        <div className="card-8 d-flex flex-column bg-white mb-3 py-3 px-4">
            <div className="d-flex flex-row">
                <img className="news-img rounded-3" src={props.item.img} alt="" />
                <div className="d-flex flex-column mx-3">
                    <Link className='bold fs-3 fg-purple-700 no-decor' to={'/'}>
                        {props.item.name}
                    </Link>
                    <span className="mt-1 text-secondary">{props.item.description}</span>
                </div>
            </div>
            <div className="d-flex flex-row align-items-center justify-content-between mt-2">
                <div className='d-flex align-items-center mt-2'>
                    <FontAwesomeIcon className='text-secondary me-2' icon={faCalendar} />
                    <span className='small medium text-secondary me-2'>{Keys.PUBLISHED}:</span>
                    <span className="small medium ms-2 fg-dark">{props.item.publishedDate}</span>
                </div >
                <Link className="small no-decor" to={'/'}>
                    {Keys.READ_MORE}
                    <FontAwesomeIcon className='ms-2' icon={faArrowUpRightFromSquare} />
                </Link>
            </div>
        </div>
    );
}

export default NewsItem;