import { faBuilding, faCircleDot, faFileSignature } from "@fortawesome/free-solid-svg-icons";
import ListGroupU from "../../../components/ui/ListGroupU";
import Keys from "../../../utils/constants";

const Tenders = () => {
    const listGroup = [
        { name: Keys.CONTRACTS, icon: faFileSignature, link: '/informations/contracts' },
        { name: Keys.TENDER_STATUSES, icon: faCircleDot, link: '/informations/tender-statuses' },
        { name: Keys.BID_STATUSES, icon: faCircleDot, link: '/informations/bid-statuses' },
        { name: Keys.BUSINESS_TYPES, icon: faBuilding, link: '/informations/business-types' }
    ];

    return (
        <div className="card-16-outline m-3 p-3">
            <span className="fs-3 medium mb-2">{Keys.INFORMATIONS}/{Keys.TENDERS}</span>
            <ListGroupU items={listGroup}/>
        </div>
    );
}

export default Tenders;