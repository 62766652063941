import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Keys from "../../utils/constants";
import { faChartPie, } from "@fortawesome/free-solid-svg-icons";
import { faFlagCheckered, faNewspaper, faCube } from '@fortawesome/free-solid-svg-icons';
import TenderItem from "./TenderItem";
import ApexChart from "./../../components/charts/ChartColumRotatedLabel";
import NewsItem from "./NewsItem";
import { Link } from "react-router-dom";
import imgOilGas from '../../assets/news/oil_and_gas.jpg';
import imgExhibition from '../../assets/news/exhibitions.jpg';

const Main = () => {
    const tenderItems = [
        { 
            id: "1",
            name: "Elektro-Energiýanyn onümini tygşytly ulandyrmak boýunça basleşikler",
            description: "Basleşik 2 tapgyrdan ybarat bolup, olar Suw we Güneş elektrik stansiýalarynda elektrigi öndürmek we eksport üçin...",
            category: "Tehnologiýa",
            publishedDate: "21/12/2023",
            issueDate: "30/12/2023 09:00",
            dueDate: "10/01/2024 21:00",
            lots: [
                { name: "Suw elektrik stansiýasy" },
                { name: "Güneş elektrik stansiýasy" }
            ]
        },
        { 
            id: "2",
            name: "Emeli zehini önümçilikde ulanmak we awtomatlaşdyrmak",
            description: "Basleşik 2 tapgyrdan ybarat bolup, olar islendik bir jisimi tanap bilme ukyby we Önümçiligiň hilini artdyrmak esasynda...",
            category: "Tehnologiýa",
            publishedDate: "27/12/2023",
            issueDate: "13/01/2024 09:00",
            dueDate: "06/02/2024 21:00",
            lots: [
                { name: "Jisim tanap bilme ukyby" },
                { name: "Önümçiligiň hilini artdyrmak" }
            ]
        },
        { 
            id: "3",
            name: "Himiki elementler esasynda maddany anyklap bilýän programma üpjünçiligi",
            description: "Basleşik 4 tapgyrdan ybarat bolup, olar Maddany sortirowka etmek, Fiziki enjamlar bilen utgaşdyrmak, Çig mal bilen üpjün emek, we Dolandyryş...",
            category: "Bilim",
            publishedDate: "29/12/2023",
            issueDate: "22/01/2024 09:00",
            dueDate: "28/01/2024 21:00",
            lots: [
                { name: "Maddany sortirowka etmek" },
                { name: "Fiziki enjamlar bilen utgaşdyrmak" },
                { name: "Çig mal bilen üpjün etmek" },
                { name: "Dolandyryş we awtomatlaşdyrmak" }
            ]
        }
    ]

    const newsItems = [
        {
            id: "1",
            name: "Aşgabat şäherinde täze tender yglan ediler",
            description: "Döwlet esasynda geçiriljek uly bäsleşiklerden biri bolan ASHGABAT SERGISI atly tenderi geçiriljekdir. Hojalyk jemgyýeti we Hojalyk kärhanasy bolan iş ýerleri gatnaşyp biler...",
            publishedDate: "29/12/2023",
            img: imgExhibition
        },
        {
            id: "2",
            name: "Welaýat derejesinde 3 pudak boýunça ýaryş geçiriler",
            description: "Başda Hojalyk kärhanasy bolmak bilen Nebiti gaýtadan işlemek, Derman senagatynda ýurduň öz içinde derman öndürmek, we Agyz suwyň arassalygynyň hilini artdyrmak boýyunça...",
            publishedDate: "29/12/2023",
            img: imgOilGas
        },
    ]

    return (
        <div>
            <div className="w-100 d-flex justify-content-center">
                <div className="w-50 card-16-outline m-3 p-3">
                    <span className="fs-5 medium mb-2">{Keys.TODAYS_STA}</span>
                    <div className="w-100 d-flex align-items-center justify-content-around">
                        <div className="w-25 card-8 align-items-center mx-2 my-2 p-3 bg-blue-100">
                            <FontAwesomeIcon className="rounded-circle bg-blue-400 p-2 fs-5 text-white" icon={faChartPie}/>
                            <span className="bold fs-5 my-2">120</span>
                            <span className="small text-center">{Keys.USER}</span>
                        </div>
                        <div className="w-25 card-8 align-items-center mx-2 my-2 p-3 bg-yellow-100">
                            <FontAwesomeIcon className="rounded-circle bg-yellow-400 p-2 fs-5 text-white" icon={faCube}/>
                            <span className="bold fs-5 my-2">500</span>
                            <span className="small text-center">{Keys.LOT}</span>
                        </div>
                        <div className="w-50 card-8 align-items-center mx-2 my-2 p-3 bg-green-100">
                            <FontAwesomeIcon className="rounded-circle bg-green-400 p-2 fs-5 text-white" icon={faFlagCheckered}/>
                            <span className="bold fs-5 my-2">25</span>
                            <span className="small text-center">{Keys.TENDER}</span>
                        </div>
                    </div>
                </div>
                <div className="w-50 card-16-outline my-3 me-3 pt-2 px-3">
                    <ApexChart/>
                </div>
            </div>
            <div className="d-flex flex-col flex-fill card-16-outline mx-3 p-3">
                <div className="d-flex flex-row align-items-center justify-content-between mb-2">
                    <span className="fs-5 medium">
                        <FontAwesomeIcon className="me-2" icon={faFlagCheckered} />
                        {Keys.LATEST_TENDERS}
                    </span>
                    <Link className="no-decor medium" to={'/'}>{Keys.SHOW_ALL}</Link>
                </div>
                {
                    tenderItems.map((item) => {
                        return( <TenderItem item={item} key={item.id}/> )
                    })
                }
            </div>
            <div className="d-flex flex-col flex-fill card-16-outline mx-3 mt-3 p-3">
                <div className="d-flex flex-row align-items-center justify-content-between mb-2">
                    <span className="fs-5 medium">
                        <FontAwesomeIcon className="me-2" icon={faNewspaper} />
                        {Keys.LATEST_NEWS}
                    </span>
                    <Link className="no-decor medium" to={'/'}>{Keys.SHOW_ALL}</Link>
                </div>
                {
                    newsItems.map((item) => {
                        return( <NewsItem item={item} key={item.id}/> )
                    })
                }
            </div>
        </div>
    );
}

export default Main;