const Status = ({...props}) => {
    return (
        <>
            { props.status
                ? <span className="badge bg-green-100 text-success rounded-pill">Işjeň</span>
                : <span className="badge bg-red-100 text-danger rounded-pill">Işjeň däl</span> 
            }
        </>
        
    );
}

export default Status;