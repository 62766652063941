import { useEffect, useState } from 'react';
import { useParams } from "react-router";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faSquareCaretLeft } from '@fortawesome/free-solid-svg-icons';
import AxiosHelper from '../../api/AxiosHelper';
import Keys from '../../utils/constants';
import Info from './info';
import Update from './update';

const Main = () => {
    const params = useParams();
    
    const [user, setUser] = useState({});
    const [toggleBtn, setToggleBtn] = useState({class: 'btn-outline-success', text: Keys.EDIT, icon: faPenToSquare});
    const [toggleUserInfoUpdate, setToggleUserInfoUpdate] = useState(true);

    useEffect(() => {
        getUser();
    }, [params.id]);

    const getUser = () => {
        AxiosHelper.getUser(params.id).then((res) => {
            if (res.status == 200) {
                setUser(res.data.data);
            }
        }).catch((e) => { console.log(e); });
    }

    const toggleUserUi = () => {
        setToggleUserInfoUpdate(!toggleUserInfoUpdate);
        !toggleUserInfoUpdate ?
        setToggleBtn({class: 'btn-outline-success', text: Keys.EDIT, icon: faPenToSquare})
        : setToggleBtn({class: 'btn-outline-warning', text: Keys.BACK, icon: faSquareCaretLeft});
    }

    return (
        <div className="d-flex flex-column m-3 p-3 card-16-outline">
            <div className='d-flex align-items-center justify-content-between'>
                <span className='fs-3 bold mb-3'>@{user.username}</span>
                <button className={`btn card-8 bg-gradient btn-sm ${toggleBtn.class}`} onClick={toggleUserUi}>
                    <FontAwesomeIcon className='me-2' icon={toggleBtn.icon}/>{toggleBtn.text}
                </button>
            </div>
            {toggleUserInfoUpdate ? <Info user={user}/> : <Update user={user}/> }
        </div>
    );
}

export default Main;