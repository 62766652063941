import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faTrash, faPlus, faSearch, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import InputField from '../../components/form/InputField';
import TableStd from '../../components/ui/TableStd';
import DropdwonU from '../../components/ui/DropdownU'
import ModalU from '../../components/ui/ModalU';
import AxiosHelper from '../../api/AxiosHelper';
import Keys from '../../utils/constants';
import Status from '../../components/ui/Status';
import Selectable from '../../components/ui/Selectable';
import PaginationU from '../../components/ui/PaginationU';
import LimitU from '../../components/ui/LimitU';
import DataNotFound from '../../components/ui/DataNotFound';

const Main = () => {
    const navigate = useNavigate();
    
    const [tenders, setTenders] = useState([]);
    const [tendersCount, setTendersCount] = useState(3);
    const [findTender, setFindTender] = useState("");
    const [modalTenderDeletionShow, setModalTenderDeletionShow] = useState(false);
    const [tenderToBeDeleted, setTenderToBeDeleted] = useState({});

    const [filter, setFilter] = useState({page:1, limit:10});

    const deleteTender = () => {
        AxiosHelper.deleteTender(tenderToBeDeleted.id).then((res) => {
            if (res.status == 200) {
                // todo
            }
        }).catch((e) => { console.log("TEMPORARY NOT ACCESSIBLE REQUEST :|"); });
    }

    const tenderItems = [
        { 
            id: "1",
            name: "Elektro-Energiyanyn onumini tygsytly ulandyrmak boyunca baslesikler",
            category: "Tehnologiya",
            publishedDate: "21/12/2023",
            issueDate: "30/12/2023 09:00",
            dueDate: "10/01/2024 21:00",
            lotCount: 4
        },
        { 
            id: "2",
            name: "Emeli zehini onumcilikde ulanmak we awtomatlasdyrmak",
            category: "Tehnologiya",
            publishedDate: "27/12/2023",
            issueDate: "13/01/2024 09:00",
            dueDate: "06/02/2024 21:00",
            lotCount: 2
        },
        { 
            id: "3",
            name: "Himiki elementler esasynda maddany anyklap bilyan programma upcunciligi",
            category: "Bilim",
            publishedDate: "29/12/2023",
            issueDate: "22/01/2024 09:00",
            dueDate: "28/01/2024 21:00",
            lotCount: 3
        }
    ]

    const modelAttrs = [ 
        { 
            name: Keys.NAME,
            value: (attr) => { return(<Link to={`/tenders/${attr.id}`} className='no-decor'>{attr.name}</Link>) } 
        },
        {
            name: Keys.CATEGORY,
            value: (attr) => { return(<>{attr.category}</>) }
        },
        {
            name: Keys.LOT_COUNT,
            value: (attr) => { return(<>{attr.lotCount}</>) }
        },
        {
            name: Keys.PUBLISHED,
            value: (attr) => { return(<>{attr.publishedDate}</>) }
        },
        {
            name: "",
            value: (attr) => {
                const options = [
                    {
                        name: Keys.SHOW,
                        icon: faEye,
                        color: "text-dark",
                        onClick: () => { navigate(`/tenders/${attr.id}`) },
                    },
                    {
                        name:Keys.DELETE,
                        icon: faTrash,
                        color: "text-danger",
                        onClick: () => {
                            setModalTenderDeletionShow(true);
                            setTenderToBeDeleted(attr);
                         }
                    }
                ]
                return (
                    <DropdwonU className='d-flex align-items-center justify-content-center' toggleClass={"card-8-outline py-2 px-1"} icon={faEllipsisV} items={options}/>
                )
            }
        }
    ];

    useEffect(() => {
        getTenders();
    },[filter]);

    const getTenders = () => {
        AxiosHelper.getTenders(filter).then((res) => {
            if (res.status == 200) {
                setTenders(res.data.data);
                setTendersCount(res.data.count);
            }
        }).catch((e) => { console.log(e); });
    }

    return (
        <div className="d-flex flex-column m-3 p-3 card-16-outline">
            <ModalU show={modalTenderDeletionShow} title={Keys.WARNING} actionbtnvariant={"danger"} actionbtntext={Keys.DELETE} onClick={deleteTender} onHide={() => setModalTenderDeletionShow(false)}>
                <div className='d-flex flex-column align-items-center'>
                    <span className='text-dark medium'>{tenderToBeDeleted.name}</span>
                    <div className='mb-2'>
                        <span className='small medium me-2'>{Keys.LOT_COUNT}: {tenderToBeDeleted.lotCount}</span>
                    </div>
                    <span className='medium text-danger'>{Keys.DATA_DELETION_CONFIRMATION}</span>
                </div>
            </ModalU>
            <div className='d-flex align-items-center justify-content-between'>
                <span className='fs-2 bold mb-3'>{Keys.TENDERS}</span>
                <Link to={'/tenders/create'} className='btn card-8 btn-outline-success bg-gradient btn-sm'>
                    <FontAwesomeIcon className='me-2' icon={faPlus}/>{Keys.CREATE_TENDER}
                </Link>
            </div>
            { tendersCount ?
                <div className='px-3 pt-3 bg-white rounded-3'>
                    <div className='d-flex align-items-center justify-content-between mb-3'>
                        <div>
                            <InputField type={"text"} name={"find_tender"} value={findTender} onChange={(e) => setFindTender(e.target.value)} hint={Keys.FIND} icon={faSearch} formClass="input-field-container" inputClass="input-field bg-light"/> 
                        </div>
                        <div className='d-flex align-items-center justify-content-center'>
                            <Selectable size={"sm"} title={Keys.STATUS} items={Keys.ENUM_STATUS}/>
                        </div>
                    </div>
                    <TableStd modelAttrs={modelAttrs} data={tenderItems}/>
                    <div className='col-12 d-flex align-items-center justify-content-between'>
                        <LimitU size={"sm"} filter={filter} setFilter={setFilter}/>
                        <PaginationU filter={filter} setFilter={setFilter} count={tendersCount} />
                    </div>
                </div>
                : <DataNotFound/>
            }
        </div>
    );
}

export default Main;