import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faSquareCaretLeft } from '@fortawesome/free-solid-svg-icons';
import AxiosHelper from '../../api/AxiosHelper';
import Keys from '../../utils/constants';
import Participants from './Participants';
import InputField from '../../components/form/InputField';
import { faComment, faPaperPlane } from '@fortawesome/free-regular-svg-icons';
import ChatItem from './ChatItem';

const Main = () => {
    const [users, setUsers] = useState([]);
    const [filter, setFilter] = useState({page:1, limit:10});
    const [message, setMessage] = useState('');
    
    useEffect(() => {
        getUsers();
    },[filter]);

    const getUsers = () => {
        AxiosHelper.getUsers(filter).then((res) => {
            if (res.status == 200) {
                setUsers(res.data.data);
            }
        }).catch((e) => { console.log(e); });
    }

    return (
        <div className="d-flex flex-row bg-white card-8-outline m-3">
            <Participants participants={users}/>
            <div className='d-flex flex-column justify-content-between'>
                <div className='chat-area'>
                    <ChatItem avatar={"DS"} msg={"Salam. Haýyrly günler"} date={"10:30"}/>
                    <ChatItem avatar={"KH"} msg={"Salam. Sizede"} date={"10:31"}/>
                </div>
                <div className='chat-input-area bg-light'>
                    <InputField type={"text"} name={"message"} value={message} onChange={(e) => setMessage(e.target.value)} icon={faComment} hint={Keys.MESSAGE} formClass="input-field-container" inputClass="input-field bg-light chat-input"/>
                    <button className='btn bg-primary btn-sm rounded-circle ms-3'>
                        <FontAwesomeIcon icon={faPaperPlane} className='text-white' />
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Main;