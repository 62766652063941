import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faCalendarCheck } from '@fortawesome/free-regular-svg-icons';
import { Link } from 'react-router-dom';
import Keys from "../../utils/constants";
import { faCube, faLayerGroup, faCalendarCheck as faCalendarCheckDark } from '@fortawesome/free-solid-svg-icons';
import LotItem from './LotItem';

const TenderItem = ({...props}) => {
    return(
        <div className='card-8 d-flex flex-column bg-white mb-3'>
            <Link className='card-top-8 bg-blue-100 medium fg-blue-700 px-3 py-1 no-decor' to={'/'}>
                {props.item.name}
            </Link>
            <div className="d-flex flex-row p-3">
                <div className='d-flex flex-column w-50 me-2'>
                    <span className='text-secondary small'>{props.item.description}</span>
                    <div className='d-flex align-items-center mt-1'>
                        <FontAwesomeIcon className='text-secondary me-2' icon={faLayerGroup} />
                        <span className='small medium text-secondary me-2'>{Keys.CATEGORY}:</span>
                        <span className='small fg-dark'>{props.item.category}</span>
                    </div>
                    <div className='d-flex align-items-center mt-2'>
                        <FontAwesomeIcon className='text-secondary me-2' icon={faCalendar} />
                        <span className='small medium text-secondary me-2'>{Keys.PUBLISHED}:</span>
                        <span className="small medium ms-2 fg-dark">{props.item.publishedDate}</span>
                    </div >
                </div>
                <div className='d-flex flex-column w-25'>
                    <span className='medium text-secondary'>
                        <FontAwesomeIcon className='text-secondary me-2' icon={faCube} />
                        {Keys.LOT}
                    </span>
                    {
                        props.item.lots.map((lot, index) => {
                            return (<LotItem item={lot} index={index} key={lot.name}/>)
                        })
                    }
                </div>
                <div className='d-flex flex-column align-items-center w-25'>
                    <div className='d-flex flex-column'>
                        <span className='badge bg-info'>{Keys.ISSUE_DATE}</span>
                        <span className='small medium mt-1 fg-dark'>
                            <FontAwesomeIcon className='text-secondary me-2' icon={faCalendarCheck} />
                            {props.item.issueDate}
                        </span>
                    </div>
                    <div className='d-flex flex-column mt-3'>
                        <span className='badge bg-dark'>{Keys.DUE_DATE}</span>
                        <span className='small medium mt-1 fg-dark'>
                            <FontAwesomeIcon className='text-secondary me-2' icon={faCalendarCheckDark} />
                            {props.item.dueDate}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TenderItem;