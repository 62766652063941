export default class Utils {
    static getAvatar(user) {
        try {
            return user.firstName.charAt(0) + user.lastName.charAt(0);
        } catch (err) {}
    }

    static getFirstChar(s) { return s.charAt(0); }

    static toggleSidebar() {
        let el = 'body'.trim();
        document.querySelector(el).classList.toggle('toggle-sidebar');
    }
}