import Keys from "../utils/constants";
import NavBar from "./NavBar";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlagCheckered, faCircleQuestion } from '@fortawesome/free-solid-svg-icons';


const SideBar = (props) => {
    const logo = require('../assets/logo_light.png');

    return (
        <nav className="sidebar">
            <div>
                <Link to={'/'} className="d-flex align-items-center no-decor mx-2 mb-5">
                    <FontAwesomeIcon className='text-white logo-sm me-2' icon={faFlagCheckered}/>
                    <span className="fs-4 text-dark bold">{Keys.APP_NAME}</span>
                </Link>
                <NavBar/>
            </div>

            <div className="card-help bg-gradient">
                <FontAwesomeIcon className='text-white fs-1' icon={faCircleQuestion}/>
                <span className="fs-4 bold text-white mt-2">{Keys.SUPPORT}</span>
                <span className="text-white small text-center mb-3">{Keys.TECH_SUPPORT}</span>
                <Link to="/help" className="no-decor text-dark fs-6 medium px-3 py-1 bg-white rounded-3">
                    {Keys.DETAIL}
                </Link>
            </div>
        </nav>
    );
}

export default SideBar;