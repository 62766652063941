import Wrapper from "../../layouts/Wrapper";
import TabBar from "../../components/ui/TabBar";
import Keys from "../../utils/constants";
import Common from "./Tabs/Common";
import Tenders from "./Tabs/Tenders";
import Admin from "./Tabs/Admin";

const Information = () => {
    const tabs = [
        { eventKey: 'common', title: Keys.COMMON, content: <Common/> },
        { eventKey: 'tenders', title: Keys.TENDERS, content: <Tenders/> },
        { eventKey: 'admin', title: Keys.ADMIN, content: <Admin/> }
    ]

    return (
        <Wrapper>
            <TabBar tabs={tabs} className={"m-3"}/>
        </Wrapper>
    );
}

export default Information;