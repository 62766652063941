import { useState } from 'react';
import Keys from '../../utils/constants';
import Status from '../../components/ui/Status';
import Selectable from '../../components/ui/Selectable';
import Utils from '../../utils';

const Update = ({...props}) => {
    const [firstName, setFirstName] = useState(props.user.firstName);
    const [lastName, setLastName] = useState(props.user.lastName);
    const [middleName, setMiddleName] = useState(props.user.middleName);
    const [phoneNumber, setPhoneNumber] = useState(props.user.phoneNumber);
    const [active, setActive] = useState(props.user.active);


    const handleSubmit = (event) => {
        event.preventDefault();
    }

    return (
        <div>
            <form onSubmit={handleSubmit} noValidate>
                <div className='row'>
                    <div className="col-md-4">
                        <label className="form-label text-dark bold">{Keys.FIRSTNAME}</label>
                        <input type="text" name="firstName" value={firstName} onChange={(e) => setFirstName(e.target.value)} className="form-control" placeholder={Keys.FIRSTNAME} required/>
                    </div>
                    <div className="col-md-4">
                        <label className="form-label text-dark bold">{Keys.LASTNAME}</label>
                        <input type="text" name="lastName" value={lastName} onChange={(e) => setLastName(e.target.value)} className="form-control" placeholder={Keys.LASTNAME} required/>
                    </div>
                    <div className="col-md-4">
                        <label className="form-label text-dark bold">{Keys.MIDDLENAME}</label>
                        <input type="text" name="middleName" value={middleName} onChange={(e) => setMiddleName(e.target.value)} className="form-control" placeholder={Keys.MIDDLENAME} required disabled/>
                    </div>
                </div>
                <div className='row mt-3'>
                    <div className="col-md-4">
                        <label className="form-label text-dark bold">{Keys.PHONE_NUMBER}</label>
                        <input type="text" name="phoneNumber" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} className="form-control" placeholder={Keys.PHONE_NUMBER} required/>
                    </div>
                </div>
                <div className="form-check form-switch mt-3">
                    <label className="form-check-label text-dark bold">{Keys.STATUS}</label>
                    <input type="checkbox" name="active" value={active} checked={active} onChange={(e) => setActive(!active)} className="form-check-input" />
                </div>
                <button type="submit" className="btn btn-success bg-gradient mt-4">{Keys.SAVE}</button>
            </form>
        </div>
    );
}

export default Update;