import Keys from '../../utils/constants';
import Status from '../../components/ui/Status';
import Selectable from '../../components/ui/Selectable';
import Utils from '../../utils';

const Info = ({...props}) => {
    return (
        <div>
            <div className='d-flex p-3 bg-white rounded-3'>
                <div className='d-flex flex-column align-items-center py-4 px-5 border rounded-2'>
                    <span className='avatar border border-light p-5 bold fs-1 bg-yellow-100 text-black'>{Utils.getAvatar(props.user)}</span>
                    <span className='fs-5 mt-2 medium'>{"Admin"}</span>
                    <div className='mt-2 d-flex align-items-center'>
                        <span className='me-2 small'>{Keys.STATUS}:</span>
                        <Status status={props.user.active} />
                    </div>
                </div>
                <div className='d-flex flex-column ms-5'>
                    <span className='fs-3 medium mb-3'>{props.user.fullName}</span>
                    <span className='fs-5 medium fg-blue-700'>{props.user.phoneNumber}</span>
                    <span className='fs-5'>{"example@gmail.com"}</span>
                    <span className='mt-3'>{Keys.REGISTRATION_DATE}: {props.user.registrationDate}</span>
                </div>
            </div>
            <div className='d-flex flex-column p-3 bg-white rounded-3 mt-3'>
                <span className='fs-3 medium mb-3'>{Keys.ADDRESS}</span>
                <div className='d-flex align-items-center'>
                    <span className='col-md-2 medium me-3 text-secondary'>{Keys.PROVINCE}:</span>
                    <span className='col-md-10'>{Keys.PROVINCE}</span>
                </div>
                <div className='d-flex align-items-center mt-2'>
                    <span className='col-md-2 medium me-3 text-secondary'>{Keys.DISTRICT}:</span>
                    <span className='col-md-10'>{Keys.DISTRICT}</span>
                </div>
                <div className='d-flex align-items-center mt-2'>
                    <span className='col-md-2 medium me-3 text-secondary'>{Keys.ADDRESS}:</span>
                    <span className='col-md-10'>{Keys.ADDRESS}</span>
                </div>
            </div>
        </div>
    );
}

export default Info;