import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AxiosHelper from '../../api/AxiosHelper';
import Keys from '../../utils/constants';
import Selectable from '../../components/ui/Selectable';
import { faSave } from '@fortawesome/free-regular-svg-icons';
import LotForm from './LotForm';
import LotItem from './LotItem';

const Main = () => {
    const [categories, setCategories] = useState([]);
    const [statuses, setStatuses] = useState([]);

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [issueDate, setIssueDate] = useState('');
    const [dueDate, setDueDate] = useState('');
    const [statusId, setStatusId] = useState('');
    const [categoryId, setCategoryId] = useState('');
    const [lotName, setLotName] = useState('');
    const [lotDescription, setLotDescription] = useState('');

    const [lotsTemp, setLotsTemp] = useState([]);

    const handleSubmit = (event) => {
        event.preventDefault();

        if (name == '') {

        }

        AxiosHelper.createTender().then((response) => {
            if (response.status == 200) {
                // todo
            }
        }).catch((e) => { console.log(e)})
    }

    const addTempLot = () => {
        if (lotName == '' && lotDescription == '') {
            console.log('lot fields are empty');
            return;
        }

        const lotTemp = { name: lotName, description: lotDescription }
        
        setLotName('');
        setLotDescription('');
        setLotsTemp([...lotsTemp, lotTemp]);
    }

    return (
        <div className="d-flex flex-column m-3 p-3 card-16-outline">
            <div className='d-flex align-items-center justify-content-between'>
                <span className='fs-3 bold mb-3'>{Keys.TENDER}</span>
                <button type='submit' className='btn btn-outline-success btn-sm'>
                    <FontAwesomeIcon className='me-2' icon={faSave}/>
                    {Keys.SAVE}
                </button>
            </div>
            <form onSubmit={handleSubmit} noValidate>
                <div className="mb-3">
                    <label className="form-label text-dark bold">{Keys.NAME}</label>
                    <input type="text" name="name" value={name} onChange={(e) => setName(e.target.value)} className="form-control" placeholder={Keys.NAME} required/>
                </div>
                <div className="mb-3">
                    <label className="form-label text-dark bold">{Keys.DESCRIPTION}</label>
                    <textarea type="text" name="description" rows={6} value={description} onChange={(e) => setDescription(e.target.value)} className="form-control" placeholder={Keys.DESCRIPTION} required/>
                </div>
                <div className='d-flex'>
                    <div className='flex-fill me-3'><Selectable value={statusId} setValue={setStatusId} size={"sm"} title={Keys.STATUS} items={Keys.ENUM_STATUS}/></div>
                    <div className='flex-fill'><Selectable value={categoryId} setValue={setCategoryId} size={"sm"} title={Keys.CATEGORY} items={categories}/></div>
                </div>
                <div className='d-flex mt-3'>
                    <div className='flex-fill me-3'>
                        <label className="form-label text-dark bold">{Keys.ISSUE_DATE}</label>
                        <input type='date' name='issueDate' value={issueDate} onChange={(e) => setIssueDate(e.target.value)} className='form-control' required/>
                    </div>
                    <div className='flex-fill'>
                        <label className="form-label text-dark bold">{Keys.DUE_DATE}</label>
                        <input type='date' name='issueDate' value={dueDate} onChange={(e) => setDueDate(e.target.value)} className='form-control' required/>
                    </div>
                </div>
            </form>
            <hr className='my-4'/>
            <LotForm lotName={lotName} setLotName={setLotName} lotDescription={lotDescription} setLotDescription={setLotDescription} onSubmit={addTempLot}/>
            {
                lotsTemp.map((lot, index) => {
                    return (
                        <LotItem lot={lot} index={index} onDelete={() => {
                            const updatedLotsTemp = lotsTemp.filter(item => item.name !== lot.name);
                            setLotsTemp(updatedLotsTemp);
                        }} key={lot.name}/>
                    )
                })
            }
        </div>
    );
}

export default Main;