import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import Keys from '../../utils/constants';
import Selectable from '../../components/ui/Selectable';


const LotForm = ({...props}) => {
    const [products, setProducts] = useState([]);
    const [productId, setProductId] = useState('');
    
    return (
        <form>
            <div className='d-flex flex-column'>
                <div className='d-flex align-items-center justify-content-between'>
                    <span className='bold fs-3 my-2'>{Keys.LOT}</span>
                    <button onClick={props.onSubmit} type='button' className='btn btn-outline-primary btn-sm mt-2'>
                        <FontAwesomeIcon className='me-2' icon={faPlus}/>
                        {Keys.ADD}
                    </button>
                </div>
                <div className='card-8-outline bg-white p-2'>
                    <div className="mb-3">
                        <label className="form-label text-dark bold">{Keys.NAME}</label>
                        <input type="text" name="name" value={props.lotName} onChange={(e) => props.setLotName(e.target.value)} className="form-control" placeholder={Keys.NAME} required/>
                    </div>
                    <div className="mb-3">
                        <label className="form-label text-dark bold">{Keys.DESCRIPTION}</label>
                        <textarea type="text" name="description" rows={4} value={props.lotDescription} onChange={(e) => props.setLotDescription(e.target.value)} className="form-control" placeholder={Keys.DESCRIPTION} required/>
                    </div>
                    <div className='flex-fill mb-3'><Selectable value={productId} setValue={setProductId} size={"sm"} title={Keys.PRODUCT} items={products}/></div>
                </div>
            </div>
        </form>
    );
}

export default LotForm;